@use 'variables' as *

.loading-spinner
    position: absolute
    left: 50%
    top: 50%
    height: 60px
    width: 60px
    transform: translate(-50%, -50%)
    z-index: 9997

.loading-spinner div
    box-sizing: border-box
    display: block
    position: absolute
    width: 45px
    height: 45px
    margin: 8px
    border: 5px solid $satys-accent
    border-radius: 50%
    animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: $satys-accent transparent transparent transparent

.loading-spinner div:nth-child(1)
    animation-delay: -0.45s

.loading-spinner div:nth-child(2)
    animation-delay: -0.3s

.loading-spinner div:nth-child(3)
    animation-delay: -0.15s

@keyframes loading-spinner
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)
